// Colors
$orange-color: #fead20;
$gray-color-1: #797b8b;
$gray-color-2: #404457;
$gray-color-3: #323446;
$gray-color-4: #232532;


// Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap');

$font-1: 'Tw Cen MT';
$font-2: 'Poppins', sans-serif;

// Animations

@keyframes move {
    0% {
        opacity: 1;
        transform: translateY(300px);
    }   

    100% {
        opacity: 0;
        transform: translateY(-400px);
    }
}    

@mixin move-element($velocity) {
    opacity: 0;
    animation: move $velocity infinite;
}