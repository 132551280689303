.nav_options {
    width: 300px;

    display: flex;
    gap: 20px;

    .option {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .option_name {
            color: $gray-color-1;
            font-size: 19px;
        }

        &:hover {
            cursor: pointer;
        }        
    }

    .active {
        ::before {
            content: ' ';
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background: $orange-color;
            color: $orange-color;

            position: absolute;
            bottom: -10px;
            left: 50%;
        }

        .option_name {
            color: #FFFF;
        }
    }

}

@media screen and (max-width: 829px) {
    .nav_options {
        width: auto;
    }
}