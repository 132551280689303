.section_header {
    background:$gray-color-3;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;

    .center_header {
        max-width: $max-width-page;
        margin: auto;

        display: flex;
        justify-content: space-between;
        padding: 30px 20px;

        .logo {
            font-family: $font-1;
            color: $orange-color;
            font-size: 27px;
            font-weight: 700;
        }
    }
}

