.loading {
    width: 260px;
    height: 142px;
    background: #292b3a;

    &::before {
        content: '';
        position: absolute;
        width: 60%;
        height: 100%;
        background: linear-gradient(90deg , rgba(0, 0, 0, 0),#2c2f3f, #2c2f3f, #2c2f3f, rgba(0, 0, 0, 0)) ;
        transform: translateX(-110px);
        animation: loading_animation 2s infinite;
    } 
}

@keyframes loading_animation {
    0% {
        transform: translateX(-140px);
    }
    90% {
        transform: translateX(270px);
    }
    100% {
        transform: translateX(270px);
    }
}
