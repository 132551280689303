.section_banner {
    // height: 400px;
    background: $gray-color-3;
    position: fixed;
    top: 88px;
    left: 0;
    right: 0;
    z-index: -1;

    display: flex;
    justify-content: center;

    .center_banner {
        
        max-width: $max-width-page;
        margin: auto;
        padding: 5em 30px;

        display: flex;
        // justify-content: space-between;
        gap: 100px;
        flex-wrap: wrap;

        .presentation {
            display: flex;
            flex-direction: column;
            justify-content: center;

            gap: 30px;

            .title_name {
                width: 370px;
                padding: 0 0 0 30px;
                border-left: 4px solid $orange-color;

                .im {
                    color: #ffffff80;
                    font-size: 3em;
                    font-weight: 700;
                    font-size: 2.5em;
                    line-height: 1em;
                    width: 100px;
                    margin: 0 0 10px 0;
                }
                
                .my_name {
                    // color: $orange-color;
                    color: #FFFF;
                    font-size: 3.5em;
                    width: 300px;
                    font-weight: 700;
                    font-size: 3em;
                    line-height: 1em;
                    width: 100px;
                }

            }

            .social_networks {
                .icon_github {
                    fill: #FFF;
                    width: 30px;
                }
            }
        }

        .about {
            width: 300px;
            background: $gray-color-3;
            display: flex;
            flex-direction: column;
            justify-content: center;

            gap: 15px;
            
            .subtitle {
                color: $gray-color-1; 
                font-size: 15px;
            }

            .title {
                color: #FFFF;
                font-size: 30px;
                width: 100px;
                line-height: 27px;
            }

            .information {
                color: $gray-color-1;
                width: 250px;
                font-size: 19px;
            }

        }
    }
}

@media screen and (max-width: 820px) {

    .section_banner {
        .center_banner {
            gap: 50px;
        }
    }
}