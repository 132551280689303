.section_works {
    min-height: 335px;
    background: $gray-color-4;
    margin: 460px 0 0;

    .center_works {
        max-width: $max-width-page;
        margin: auto;
        padding: 30px 40px;


        .header_works {

            .title_works {
                color: #FFFF;
                font-size: 2em;
            }
        }

        .projects {
            margin: 30px 0;

            display: flex;
            // flex: 1 1 auto;
            flex-wrap: wrap;
            gap: 30px 30px;
        }

        .options {
            display: flex;
            justify-content: flex-end;
            .show_more {
                color: $orange-color;
                
                &:hover {
                    cursor: pointer;
                    border-bottom: 1px solid $orange-color;
                }
    
            }
        }
    }

    .slide_show {
        width: 100%;
        // height: 200px;
        margin: auto;
        overflow: hidden;
    }
}

@media screen and (max-width: 829px) {
    .section_works {
        margin: 685px 0 0 0;
    }
}