.project_card {
    position: relative;
    width: 260px;
    // height: 200px;
    overflow: hidden;
    transition: all ease-in-out 50ms;

    &:hover {
        transform: scale(1.05);

        box-shadow: 1px 1px 1px #232532;

        .header_project_card {
     
            .project_info {
                bottom: 0px;
                opacity: 100%;
            }
        }

        .body_project_card {
            opacity: 100%;
        }
    }

    .header_project_card {
        height: 100%;

        .project_image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .project_info {
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: -48px;
            opacity: 0%;
            padding: 10px;
            background: #323446da;
            backdrop-filter: blur(4px);

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;
            transition: all ease-in 150ms;
            

            .project_links {
                display: flex;
                // flex-direction: column;
                gap: 10px;

                .repositorie {
                    .icon_github {
                        fill: #FFFF;
                        width: 25px;
                        
                        &:hover {
                            fill: #c2c2c2;
                        }
                    }
                }

                .link_web {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    text-decoration: none;

                    &:hover {
                        text-decoration: 1px solid #FFFF;
                    }

                    .icon_link {
                        fill: #FFFF;
                        width: 21px;

                        &:hover {
                            fill: #c2c2c2;
                        }
                    }

                    .url {
                        width: 120px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #FFFF;
                        // font-weight: 700;
                        font-size: 13px;
                    }
                }
            }

            .creation_date {
                color: #FFFF;
                font-weight: 700;
                font-size: 11px;
            }
        }
    }

    .body_project_card {
        position: absolute;
        background: $gray-color-2;
        bottom: -45%;
        padding: 10px;
        opacity: 0%;
        transition: all ease-in-out 130ms;

        .subtitle {
            color: #FFFF;
            font-size: 20px;
        }

        .description_project {
            color: $gray-color-1;
            font-size: 15px;
            line-height: 15px;
        }
    }
}

@media screen and (max-width: 829px) {
    .project_card {
        width: 100%;
        height: 257px;
        
    }
}