.icons_animation {
    position: absolute;
    width: $max-width-page;
    height: 100%;
    z-index: -1;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 30px;


    .icon {
        fill: #383a4d;
    }

    .react_icon {
        // fill: #383a4d;
        width: 150px;
        @include move-element(7s);
        transition: all ease-in;
    }

    .html_icon {
        fill: #383a4d;
        @include move-element(9s);
        width: 100px;
    }

    .css_icon {
        @include move-element(14s);
        width: 100px;
    }

    .js_icon {
        @include move-element(10s);
        width: 120px;
        fill: #383a4d;

    }

    
}