// Config css
@import './Base/Settings';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-2;
}

// Variables
$max-width-page: 1000px;

// Import componets

// Layout
@import './Components/Layout/_Header';
@import './Components/Layout/_Nav';
@import './Components/Layout/_Works';
@import './Components/Layout/_Banner';
// Ui
@import './Components/UI/_ProjectCard';
@import './Components/UI/_IconsAnimation';
// Loading animations
@import './Components/UI/_LoadingAnimation';